import {
  MutationObserverOptions,
  QueryFunctionContext,
  useMutation
} from '@tanstack/react-query'
import post from '@/api/core/http/post'
import { buildApiUrl } from '@/api/core/url'

import { ApiError } from '@/api/core/errors'
import {
  UserMediaUnSet,
  UserMediaUnSetResponse,
  userMediaUnSetResponseSchema
} from './schema'

// https://github.com/TokenWave/dpdocs/blob/main/API.md#-usermediaset
const path = `/user/media/unset`

export const UnSetUserMedia = async (
  input: UserMediaUnSet,
  queryContext?: QueryFunctionContext
): Promise<UserMediaUnSetResponse> =>
  post<UserMediaUnSetResponse>(
    buildApiUrl(path, input),
    queryContext,
    userMediaUnSetResponseSchema
  )

export const useUnSetUserMediaMutation = (
  input: UserMediaUnSet,
  mutationOptions?: MutationObserverOptions
) =>
  useMutation<UserMediaUnSetResponse, ApiError>(
    () => UnSetUserMedia(input),
    mutationOptions
  )
