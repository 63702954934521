import React from 'react'
import { classNames } from '@/util/tailwindHelpers'
import { RedemptionCheckout } from '../../features/redemption/checkout/types'
import { Icon, Separator } from '@/components'
import { getPriceStringFromCents } from '@/util/currencyHelpers'

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  credit: RedemptionCheckout['summary']['credit']
}

export default function DropppCredit({ credit, className, ...rest }: Props) {
  if (!credit) return null

  return (
    <div
      className={classNames('rounded-3xl p-3 bg-gray-800', className)}
      {...rest}
    >
      <h4 className="h7 mb-2">Your account has available credit:</h4>
      <div className="flex flex-col md:flex-row gap-2 md:gap-3">
        <div className="flex-1">
          <h5 className="utility uppercase mb-1 text-gray-300">
            credit applied
          </h5>
          <div className="flex items-center gap-1">
            <b className="h5">{getPriceStringFromCents(credit.used)}</b>
            <Icon
              name="tick-green"
              className="w-[18px] h-[18px] flex items-center"
            />
          </div>
        </div>
        <Separator vertical={true} className="max-md:hidden" />
        <Separator className="md:hidden" />
        <div className="flex-1">
          <h5 className="utility uppercase mb-1 text-gray-300">
            credit remaining
          </h5>
          <b className="h5">{getPriceStringFromCents(credit.remaining)}</b>
        </div>
      </div>
    </div>
  )
}
