import React, { useEffect } from 'react'
import { dateToLocal } from '@/util/timeHelpers'
import { Icon } from '..'
import CircleIconLink from '@/components/CircleIconLink'
import SocialShareMenu from '@/components/SocialShareMenu'
import ProfileStickyHeader from './ProfileStickyHeader'
import ProfileStat from './ProfileStat'
import { ProfileGetResponse } from '@/api/resources/profile/schema'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { pluralize } from '@/util/stringHelpers'
import { useGetAssets } from '@/api/resources/assets/get'
import TooltipMPValueDisclaimer from '../PageSpecificComponents/marketplace/TooltipMPValueDisclaimer'
import { useAssetsValue } from '@/api/resources/assets/value/get'
import { formatUSDC } from '@/util/currencyHelpers'
import ProfileMedia from './ProfileMedia'
import { showModal } from '../Modals/ModalV2'

interface IProps {
  className?: string
  profile: ProfileGetResponse
}

export default function Profile({ profile, className }: IProps) {
  const { query, replace: replaceUrl, asPath } = useRouter()
  const { data } = useGetAssets(
    { account: profile.account.toString(), ...query },
    !!profile
  )
  const { data: assetsValue } = useAssetsValue(profile.account.toString(), {
    enabled: !!profile
  })

  // @ts-expect-error - related to infinite query not passing back type
  const { filtered_count } = data || {}
  const { inventory_value } = assetsValue || {}

  // Automatically open the profile media selector when the set-avatar flag is present
  useEffect(() => {
    const { 'set-avatar': setAvatar } = query
    if (setAvatar) {
      const url = asPath.replace('set-avatar', '')
      replaceUrl(url)
      showModal('profileEdit', {
        account: profile.account,
        profileMedia: profile.media
      })
    }
  }, [])

  const {
    account,
    is_owner,
    media,
    join_date,
    stat,
    chain_url,
    kyc_completed
  } = profile
  const { legendary, grail, redeemed, royalty_set, mythic, ultra } = stat.user

  return (
    <>
      <div className={classNames('px-2 lg:pl-15 md:mb-4', className)}>
        <div
          className="absolute w-full left-0 right-0 h-[240px] -z-[1]"
          style={{
            background: 'linear-gradient(180deg, #242424 0%, #090909 100%)'
          }}
        />

        <ProfileMedia
          className="w-[150px] h-[150px] md:w-[232px] md:h-[232px] mt-8 md:mt-10 rounded-[48px] md:rounded-[64px]"
          account={account}
          media={media}
          canEdit={is_owner}
        />

        <div className="flex mt-3 gap-1">
          <h1 className="h2 md:h1">{account}</h1>
          {kyc_completed && (
            <Icon className="mt-[9px] max-md:w-[18px]" name="verified" />
          )}
        </div>
        <p className="body md:body-lg text-gray-400 mt-2">
          Joined on {dateToLocal({ date: join_date, format: 'MMM D, YYYY' })}
        </p>
        <div className="flex gap-3 mt-3">
          <SocialShareMenu />
          <CircleIconLink name="blockchain" url={chain_url} />
        </div>

        <div className="inline-grid md:flex grid-cols-2 md:grid-cols-4 gap-x-[59px] gap-y-3 md:gap-4 mt-3">
          <ProfileStat
            className="col-span-2"
            value={inventory_value && formatUSDC(inventory_value, 2, 2)}
            label={<TooltipMPValueDisclaimer label="Est. Collection Value" />}
            isUSDC
          />
          <ProfileStat
            value={redeemed}
            label={pluralize('Redemption', redeemed)}
          />
          <ProfileStat value={mythic} label={pluralize('Mythic', mythic)} />
          <ProfileStat value={grail} label={pluralize('Grail', grail)} />
          <ProfileStat
            value={legendary}
            label={pluralize('Legendary', legendary)}
          />
          <ProfileStat value={ultra} label={pluralize('Ultra', ultra)} />
          <ProfileStat
            value={royalty_set}
            label={pluralize('Royalty Set', royalty_set)}
          />
        </div>
      </div>
      <ProfileStickyHeader
        account={account}
        avatar={media.avatar_media.size1_url}
        count={filtered_count}
      />
    </>
  )
}
