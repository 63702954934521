import Icon from '@/components/Icon'
import styles from './styles.module.scss'
import classNames from 'classnames'

type Props = {
  isSelected: boolean
  handleSelect: () => void
}
const ProfileUnSetStickerItem = ({ isSelected, handleSelect }: Props) => {
  return (
    <li key="unset" className="relative ">
      <button
        type="button"
        className={classNames(
          'aspect-1 w-full flex items-center justify-center',
          styles.asset,
          styles.isSticker,
          {
            [styles.isSelected]: isSelected
          }
        )}
        onClick={handleSelect}
      >
        {/* eslint-disable-next-line @next/next/no-img-element*/}
        <Icon name="banned" className="w-2/5 h-2/5" />
      </button>
    </li>
  )
}

export default ProfileUnSetStickerItem
