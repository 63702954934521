import { z } from 'zod'
import { successResponseSchema } from '@/api/resources/shared/success'

export const userMediaUnsetSchema = z.object({
  media_type: z.string()
})

export const userMediaUnSetResponseSchema = successResponseSchema

export type UserMediaUnSet = z.infer<typeof userMediaUnsetSchema>
export type UserMediaUnSetResponse = z.infer<
  typeof userMediaUnSetResponseSchema
>
