import React from 'react'
import { useIsMutating } from '@tanstack/react-query'

import Button, { ButtonLink } from '@/components/Button'
import Icon from '@/components/Icon'
import { CONTENT } from './content'
import { useProfileMediaContext } from './Context'

export default function Footer() {
  const { currentMediaType, hasUpdates } = useProfileMediaContext()
  const isMutating = useIsMutating()
  const content = CONTENT[currentMediaType]

  return (
    <>
      <div className="flex gap-3">
        <ButtonLink
          className="w-full "
          theme="secondary"
          href={content.shopButton.url}
        >
          {content.shopButton.label}
        </ButtonLink>
        <Button
          className="w-full"
          loading={isMutating > 0}
          disabled={!hasUpdates}
          type="submit"
        >
          Apply
        </Button>
      </div>
      <div className="flex justify-center px-[21px] gap-1 mt-2">
        <Icon name="forSale" className="flex-shrink-0 text-success" />
        <span className="body-xs text-gray-300 text-center max-w-[349px]">
          Indicates an item is listed on Droppp Marketplace. If sold, it will be
          removed from your profile.
        </span>
      </div>
    </>
  )
}
