import classnames from 'classnames'
import React from 'react'
import Button from '@/components/Button'
import styles from './styles.module.scss'

interface Props<T> {
  className?: string
  items: { label: string; value: T }[]
  value: T
  onToggle: (value: T) => void
}

const MultiToggle = <T extends string | number>({
  className,
  items,
  value,
  onToggle
}: Props<T>) => {
  return (
    <div className={classnames(styles.container, className)}>
      {items.map(item => {
        return (
          <Button
            key={item.value}
            className={classnames(styles.tab, {
              [styles.active]: item.value === value
            })}
            theme="clean"
            onClick={() => onToggle(item.value)}
          >
            {item.label}
          </Button>
        )
      })}
    </div>
  )
}

export default MultiToggle
