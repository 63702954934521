import React from 'react'
import { useCountriesQuery } from '@/api/resources/countries/get'
import { CountriesResponse } from '@/api/resources/countries/get/schema'
import ControlledSelect from './ControlledSelect'
import Select from './'
import { ControllerProps } from 'react-hook-form'

interface Props {
  className?: string
  id?: string
  value?: number
  onChange?: (countryId: number) => void
  disabled?: boolean
  formProps?: {
    control: ControllerProps['control']
    name: ControllerProps['name']
    rules?: ControllerProps['rules']
  }
}

export const CountrySelector = ({
  className,
  id,
  value,
  onChange,
  disabled,
  formProps
}: Props) => {
  const {
    data: { countries }
  } = useCountriesQuery<CountriesResponse>({
    placeholderData: { countries: [] }
  })
  const countriesMap = countries.map(({ id, name }) => ({
    value: id,
    label: name
  }))

  if (!formProps) {
    return (
      <Select
        className={className}
        id={id}
        placeholder="Select Country"
        options={countriesMap}
        value={countriesMap.find(country => country.value === value)}
        onChange={({ value }) => onChange(value)}
      />
    )
  }

  return (
    <ControlledSelect
      className={className}
      id={id}
      name={formProps.name}
      disabled={disabled}
      options={countriesMap}
      map={option => option.value}
      formProps={formProps}
    />
  )
}

export default CountrySelector
