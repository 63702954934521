import { profileGetQueryKey } from '@/api/resources/profile/get'
import { useSetUserMediaMutation } from '@/api/resources/user/media/set'
import { useUnSetUserMediaMutation } from '@/api/resources/user/media/unset'
import { MODAL_ID } from '@/constants/modalId'
import { useAuth } from '@/contexts/auth'
import { sendGTMEvent } from '@next/third-parties/google'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { hideModal } from '..'
import { useProfileMediaContext } from './Context'
import { PROFILE_MEDIA_TYPES } from './types'

export const Form = ({ children }) => {
  const { fetchUser } = useAuth()
  const { handleSubmit } = useForm()
  const queryClient = useQueryClient()
  const { selectedItems, account, updatedItems } = useProfileMediaContext()

  const { mutateAsync: setUserMediaMutationAsync } = useSetUserMediaMutation()
  const { mutateAsync: unSetUserMediaMutationAsync } =
    useUnSetUserMediaMutation({
      media_type: PROFILE_MEDIA_TYPES.sticker
    })

  const onSubmit = async () => {
    const promises = []

    if (updatedItems.avatar) {
      promises.push(
        setUserMediaMutationAsync(
          {
            chain_asset_id: selectedItems.avatar.chain_asset_id,
            media_id: selectedItems.avatar.id,
            media_type: PROFILE_MEDIA_TYPES.avatar
          },
          {
            onSuccess: () => {
              sendGTMEvent({
                event: 'avatar_change'
              })
            }
          }
        )
      )
    }

    if (updatedItems.sticker) {
      if (selectedItems.sticker.index === -1) {
        promises.push(unSetUserMediaMutationAsync())
      } else {
        promises.push(
          setUserMediaMutationAsync(
            {
              chain_asset_id: selectedItems.sticker.chain_asset_id,
              media_id: selectedItems.sticker.id,
              media_type: PROFILE_MEDIA_TYPES.sticker
            },
            {
              onSuccess: () => {
                sendGTMEvent({
                  event: 'sticker_change'
                })
              }
            }
          )
        )
      }
    }

    try {
      await Promise.all(promises)
      await fetchUser()
      await queryClient.invalidateQueries(profileGetQueryKey(account)).then()
      hideModal(MODAL_ID.profileEdit)
    } catch (error) {
      // Queries use built-in error handling
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
  return <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
}
