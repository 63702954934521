import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { PROFILE_MEDIA_TYPES } from './types'
import { ProfileMediaSchema } from '@/api/resources/profile/schema'
import { useGetUserMedia } from '@/api/resources/user/media/get'
import { UserMediaItem, UserMediaTypes } from '@/api/resources/shared/userMedia'
import { UseInfiniteQueryResult } from '@tanstack/react-query'

type SelectedItem =
  | {
      index: number | undefined
      chain_asset_id: number | undefined
      id: number | undefined
    }
  | undefined

type ProfileMediaContextType = {
  currentMediaType: UserMediaTypes
  hasUpdates: boolean
  updatedItems: Record<UserMediaTypes, boolean>
  profileMedia: ProfileMediaSchema
  mediaGetResponse: {
    avatars: UseInfiniteQueryResult
    stickers: UseInfiniteQueryResult
  }
  account: string
  selectedItems: {
    avatar: SelectedItem
    sticker: SelectedItem
  }
  items: {
    avatars: ProfileMediaSchema['avatar_media'][]
    stickers: ProfileMediaSchema['sticker_media'][]
  }
  onItemSelect: (item: SelectedItem) => void
  onViewChange: (type: UserMediaTypes) => void
}

export const ProfileMediaContext = createContext<
  ProfileMediaContextType | undefined
>({
  currentMediaType: PROFILE_MEDIA_TYPES.avatar,
  hasUpdates: false,
  updatedItems: {
    avatar: false,
    sticker: false
  },
  profileMedia: {
    avatar_media: {},
    sticker_media: {}
  },
  mediaGetResponse: {
    avatars: undefined,
    stickers: undefined
  },
  onViewChange: () => {},
  selectedItems: { avatar: undefined, sticker: undefined },
  onItemSelect: () => {},
  items: {
    avatars: [],
    stickers: []
  },
  account: ''
})

export const useProfileMediaContext = () => {
  const context = useContext(ProfileMediaContext)

  if (context === undefined)
    throw new Error(
      'useProfileMediaContext must be used within a ProfileMediaProvider'
    )

  return context
}

const getInitialItemIndex = (
  items: UserMediaItem[],
  profileMediaId: number
) => {
  const index = items.findIndex(
    item =>
      item?.chain_asset_id === profileMediaId || item?.id === profileMediaId
  )
  return index
}

export const ProfileMediaProvider = ({
  children,
  initialProfileMedia,
  account
}: PropsWithChildren<{
  account: string
  initialProfileMedia: ProfileMediaSchema
}>) => {
  const [currentMediaType, setCurrentMediaType] = useState<UserMediaTypes>(
    PROFILE_MEDIA_TYPES.avatar
  )
  const [avatar, setAvatar] = useState<SelectedItem | undefined>()
  const [sticker, setSticker] = useState<SelectedItem | undefined>()
  const isAvatarView = currentMediaType === PROFILE_MEDIA_TYPES.avatar

  const avatarsResponse = useGetUserMedia({
    media_type: PROFILE_MEDIA_TYPES.avatar
  })
  const stickersResponse = useGetUserMedia({
    media_type: PROFILE_MEDIA_TYPES.sticker
  })
  // @ts-expect-error - related to infinite query not passing back type
  const avatars = avatarsResponse?.data?.items || []
  const initialAvatarIndex = getInitialItemIndex(
    avatars,
    initialProfileMedia.avatar_media.chain_asset_id ||
      initialProfileMedia.avatar_media.id
  )
  // @ts-expect-error - related to infinite query not passing back type
  const stickers = stickersResponse?.data?.items || []
  const initialStickerIndex = getInitialItemIndex(
    stickers,
    initialProfileMedia.sticker_media?.chain_asset_id
  )

  const handleSetSelectedItem = (item: SelectedItem) => {
    isAvatarView ? setAvatar(item) : setSticker(item)
  }

  const hasAvatarChanged =
    avatar !== undefined && initialAvatarIndex !== avatar.index
  const hasStickerChanged =
    sticker !== undefined && initialStickerIndex !== sticker.index

  const selectedItems: Record<UserMediaTypes, SelectedItem> = {
    avatar: {
      index: avatar?.index ?? initialAvatarIndex,
      chain_asset_id: avatar?.chain_asset_id,
      id: avatar?.id
    },
    sticker: {
      index: sticker?.index ?? initialStickerIndex,
      chain_asset_id: sticker?.chain_asset_id,
      id: sticker?.id
    }
  }

  const profileMedia = {
    avatar_media: avatars[avatar?.index] ?? initialProfileMedia.avatar_media,
    // stickers can be undefined / unset
    sticker_media:
      sticker?.index === -1
        ? undefined
        : stickers[sticker?.index] ?? initialProfileMedia.sticker_media
  }

  return (
    <ProfileMediaContext.Provider
      value={{
        currentMediaType,
        profileMedia,
        account,
        hasUpdates: hasAvatarChanged || hasStickerChanged,
        updatedItems: {
          avatar: hasAvatarChanged,
          sticker: hasStickerChanged
        },
        mediaGetResponse: {
          avatars: avatarsResponse,
          stickers: stickersResponse
        },
        onViewChange: setCurrentMediaType,
        selectedItems,
        items: { avatars, stickers },
        onItemSelect: handleSetSelectedItem
      }}
    >
      {children}
    </ProfileMediaContext.Provider>
  )
}
