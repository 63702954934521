import React from 'react'
import ControlledSelect from './ControlledSelect'
import Select from './'
import { useStatesQuery } from '@/api/resources/states/get'
import { StatesResponse } from '@/api/resources/states/get/schema'

interface Props {
  id?: string
  value?: string
  countryId?: number
  onChange?: (value) => void
  disabled?: boolean
  formProps?: any
  selectorProps?: any
}

export const StateSelector = ({
  id,
  value,
  countryId = 1,
  onChange,
  disabled,
  formProps,
  selectorProps
}: Props) => {
  const {
    data: { states }
  } = useStatesQuery<StatesResponse>(countryId, {
    placeholderData: { states: [] }
  })
  const STATE_LIST = states.map(({ name, code }) => ({
    value: code,
    label: name
  }))

  if (!formProps) {
    return (
      <Select
        id={id}
        placeholder="Select State"
        options={STATE_LIST}
        value={STATE_LIST.find(state => state.value === value)}
        onChange={({ value }) => onChange(value)}
        {...selectorProps}
      />
    )
  }

  return (
    // @ts-expect-error
    <ControlledSelect
      id={id}
      disabled={disabled}
      options={STATE_LIST}
      map={option => option.value}
      formProps={formProps}
      selectorProps={selectorProps}
    />
  )
}

export default StateSelector
