import classNames from 'classnames'
import styles from './styles.module.scss'
import Popup from 'reactjs-popup'
import Icon from '@/components/Icon'
import { PROFILE_MEDIA_TYPES } from './types'
import { UserMediaTypes } from '@/api/resources/shared/userMedia'

type Props = {
  asset: any
  type: UserMediaTypes
  isSelected: boolean
  handleSelect: ({
    id,
    chain_asset_id
  }: {
    id: number
    chain_asset_id: number
  }) => void
}
const ProfileMediaItem = ({ asset, type, isSelected, handleSelect }: Props) => {
  const isAvatarType = type === PROFILE_MEDIA_TYPES.avatar
  const id = asset.chain_asset_id || asset.id

  return (
    <li className="relative aspect-1">
      <button
        type="button"
        className={classNames(styles.asset, {
          [styles.isSticker]: !isAvatarType,
          [styles.isSelected]: isSelected
        })}
        disabled={asset.is_locked}
        onClick={() =>
          handleSelect({ id: asset.id, chain_asset_id: asset.chain_asset_id })
        }
      >
        <>
          {/* eslint-disable-next-line @next/next/no-img-element*/}
          <img src={asset.size3_url} alt={`avatar - ${id}`} />
        </>
        {asset.is_locked && (
          <Popup
            className="lock-profile-media-tooltip"
            trigger={() => (
              <div className={styles.locked}>
                <Icon className={styles.locked__icon} size={32} name="lock" />
              </div>
            )}
            closeOnDocumentClick
            // keepTooltipInside
            position={['top center', 'top right', 'top left']}
            on={['hover', 'focus']}
          >
            <div className="flex">
              <Icon name="info" />
              <div className="font-medium body-sm text-white ml-1">
                This avatar is unlocked with purchase of a custom Droppp
                address.
              </div>
            </div>
          </Popup>
        )}
      </button>
      {asset?.mint_num && (
        <div className=" absolute -bottom-[19px] left-[50%] translate-x-[-50%] body-xs flex items-center gap-[4px]">
          {asset.marketplace_listed && (
            <Icon name="forSale" className="text-success flex-shrink-0" />
          )}
          <span>#{asset.mint_num}</span>
        </div>
      )}
    </li>
  )
}

export default ProfileMediaItem
