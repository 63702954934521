import { UserMediaTypes } from '@/api/resources/shared/userMedia'

export const CONTENT: Record<
  UserMediaTypes,
  {
    title: string
    description: string
    shopButton: {
      label: string
      url: string
    }
  }
> = {
  avatar: {
    title: 'Select An Avatar',
    description:
      'Select an avatar to set as your profile picture (PFP). You can unlock more by opening ‘Droppp Monster Series 1’ crates, or by purchasing them on Droppp Marketplace.',
    shopButton: {
      label: 'Shop Monsters',
      url: 'drop/93/droppp-monsters-series-1/shop/'
    }
  },
  sticker: {
    title: 'Select a Sticker',
    description:
      'Stickers are obtained by attending special events, participating in activities, or purchasing from Droppp Marketplace.',
    shopButton: {
      label: 'Shop Stickers',
      url: '/collection-tracker/?view=tracker&set=&collection_id=211'
    }
  }
}
