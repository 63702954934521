import { classNames } from '@/util/tailwindHelpers'
import React from 'react'
import { tv } from 'tailwind-variants'

interface Props extends React.ComponentPropsWithoutRef<'span'> {
  text: string
  theme?: 'rainbow' | 'solana' | 'mythic'
}

const gradientClass = tv({
  base: 'inline-block bg-clip-text text-transparent',
  variants: {
    theme: {
      rainbow: 'bg-rainbow--linear',
      mythic: 'bg-mythic',
      solana: 'bg-solana--linear'
    }
  }
})

export default function GradientText({
  text,
  theme = 'rainbow',
  className,
  ...rest
}: Props) {
  return (
    <span className={classNames(gradientClass({ theme }), className)} {...rest}>
      {text}
    </span>
  )
}
