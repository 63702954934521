import { DropppCredit } from '@/components/Checkout/types'

export const getSubtotalCents = (
  amount: number,
  creditBalanceCents: number
): number => {
  let subTotal = amount
  if (!!creditBalanceCents && creditBalanceCents > 0) {
    subTotal = subTotal > creditBalanceCents ? subTotal - creditBalanceCents : 0
  }
  return subTotal
}

export const calculateDropppCredit = (
  amount: number,
  creditBalanceCents: number
): DropppCredit => {
  return creditBalanceCents && amount
    ? {
        available: creditBalanceCents,
        used: creditBalanceCents < amount ? creditBalanceCents : amount,
        remaining:
          creditBalanceCents - amount <= 0 ? 0 : creditBalanceCents - amount
      }
    : null
}
