import NiceModal from '@ebay/nice-modal-react'
import { ProfileMediaSchema } from '@/api/resources/profile/schema'
import Modal from '../'
import { MODAL_ID } from '@/constants/modalId'

import { capitalize } from 'lodash'
import Body from './Body'
import Footer from './Footer'
import { ProfileMediaProvider } from './Context'
import { PROFILE_MEDIA_TYPES } from './types'
import { Form } from './Form'

export const TOGGLE_ITEMS = Object.values(PROFILE_MEDIA_TYPES).map(value => {
  return { label: capitalize(value), value }
})

interface ModalProps {
  account: string
  profileMedia: ProfileMediaSchema
  onClose: () => void
}

const ProfileEditModal = NiceModal.create<ModalProps>(
  ({ profileMedia, account }) => (
    <Modal id={MODAL_ID.profileEdit}>
      {({ ModalBody, ModalFooter }) => (
        <ProfileMediaProvider
          initialProfileMedia={profileMedia}
          account={account}
        >
          <Form>
            <ModalBody className="!pb-0">
              <Body />
            </ModalBody>

            <ModalFooter>
              <Footer />
            </ModalFooter>
          </Form>
        </ProfileMediaProvider>
      )}
    </Modal>
  )
)

export default ProfileEditModal
