import CloudinaryImage from '@/components/CloudinaryImage'
import GradientText from '@/components/GradientText'
import useBreakpoints from '@/hooks/useBreakpoints'
import Link from 'next/link'

const SolanaWalletComingSoonBlock = () => {
  const { isMobile } = useBreakpoints()

  const cloundinaryPath = 'global/wallet/'
  const imgId = isMobile ? 'wallet-coming-soon-mobile' : 'wallet-coming-soon'
  return (
    <div className="relative flex flex-1 flex-col lg:flex-row bg-gray-850 rounded-[32px] p-4 gap-0 lg:gap-4 aspect-[1024/309]">
      <div className="aspect-[269/221] lg:aspect-[321/265] lg:flex-1 relative lg:order-1 md:max-w-[421px] lg:max-w-[321px] -top-4 lg:top-0 left-[50%] -translate-x-[50%] lg:left-auto lg:translate-x-0">
        <CloudinaryImage
          imageId={imgId}
          path={cloundinaryPath}
          layout="fill"
          objectFit="contain"
        />
      </div>
      <div className="flex flex-col gap-2 max-w-[608px] lg:self-center -mt-4 lg:mt-0">
        <div className="lg:order-0 h4">
          <GradientText text="Droppp Wallet" theme="solana" />{' '}
          <span>Is Coming Soon</span>
        </div>
        <p className="body-md">
          Starting in December 2024, we will transition to a new wallet
          infrastructure. During this time your funds will remain safe, but your
          active listings and Droppp Balance will become disabled until you
          migrate to the new Droppp Wallet.
          <br />
          <br />
          We’ll provide a simple guided process to ensure your funds are
          securely transferred and your new Droppp Wallet is set up properly.
        </p>
        <p className="text-gray-300 text-xs">
          Alternatively, you can withdraw your funds to a crypto exchange or
          external wallet.
        </p>
        <Link
          href="/bloggg/important-updates-and-changes-coming"
          className="section-link mt-1"
        >
          Why is this happening?
        </Link>
      </div>
    </div>
  )
}

export default SolanaWalletComingSoonBlock
