import React from 'react'
import Image from 'next/image'
import { MODAL_ID } from '@/constants/modalId'
import Icon from '../Icon'
import { hideModal, showModal } from '../Modals/ModalV2'
import { classNames } from '@/util/tailwindHelpers'
import { ProfileMediaSchema } from '@/api/resources/profile/schema'

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  canEdit: boolean
  account?: string
  media: ProfileMediaSchema
}

export default function ProfileMedia({
  className,
  account,
  media,
  canEdit = false
}: Props) {
  return (
    <div className={classNames('relative inline-block', className)}>
      {media?.sticker_media && (
        <div className="absolute top-[0] right-[0] w-[66.5%] aspect-[1/1] z-10 rotate-12 translate-x-[30%] -translate-y-[30%]">
          <Image
            src={media.sticker_media.size3_url}
            layout="fill"
            alt="sticker"
            style={{
              filter: 'drop-shadow(5px 5px 5px rgba(9, 9, 9, 0.5))'
            }}
          />
        </div>
      )}
      <button
        className="group"
        onClick={() =>
          showModal(MODAL_ID.profileEdit, {
            account,
            profileMedia: media,
            onClose: hideModal(MODAL_ID.profileEdit)
          })
        }
        disabled={!canEdit}
      >
        <Image
          src={media?.avatar_media?.size4_url}
          layout="fill"
          objectFit="contain"
          alt={'user avatar'}
          className="rounded-[30%]"
        />
        {canEdit && (
          <div className="min-w-4 min-h-4 p-[5.75%] absolute right-[10.5%] bottom-[10.5%] flex justify-center items-center border-0 w-[20%] h-[20%] bg-black/80 group-hover:bg-gray-700/80 rounded-full">
            <Icon name="edit" className="w-full h-full" />
          </div>
        )}
      </button>
    </div>
  )
}
