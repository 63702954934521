import { z } from 'zod'

export const userMediaTypes = z.enum(['avatar', 'sticker'])
export const userMediaSchema = z.object({
  id: z.number(),
  chain_asset_id: z.number().optional(),
  type: userMediaTypes.optional(),
  size0_url: z.string(),
  size1_url: z.string(),
  size2_url: z.string(),
  size3_url: z.string(),
  size4_url: z.string(),
  mint_num: z.number().optional(),
  marketplace_listed: z.boolean().optional()
})

export type UserMediaItem = z.infer<typeof userMediaSchema>
export type UserMediaTypes = z.infer<typeof userMediaTypes>
